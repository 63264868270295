import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import i18next from "i18next";

import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

import { Dialog, DialogContent, Divider } from "@material-ui/core";
import FadeBotton from "./FadeBotton";
import Paper from "@material-ui/core/Paper";
import Zoom from "@material-ui/core/Zoom";
import Popover from "@material-ui/core/Popover";

import { Transition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import Books from "../views/TheStory/Berlin/Books";
import "./Footer1.css";

const FadeTopDelay = (props) => {
  const [open, setOpen] = useState(null);

  const duration = 1500;

  const defaultStyle = {
    transition: `opacity ${duration}ms cubic-bezier(0.31, 0.89, 0.76, 1.07),margin-top ${duration}ms cubic-bezier(0.31, 0.89, 0.76, 1.07)`,
    opacity: 0,
    marginTop: -40,
  };

  const transitionStyles = {
    entering: { opacity: 1, marginTop: 0 },
    entered: { opacity: 1, marginTop: 0 },
    exiting: { opacity: 0, marginTop: -40 },
    exited: { opacity: 0, marginTop: -40 },
  };

  useEffect(() => {
    load(); // eslint-disable-next-line
  }, [props.open]);
  const load = () => {
    console.log("entro");
    if (props.open === true)
      if (open === null) {
        setTimeout(
          () => {
            setOpen(props.open);
          },
          props.columna === 1
            ? 250
            : props.columna === 2
            ? 500
            : props.columna === 3
            ? 750
            : 1000
        );
      }
  };
  return (
    <Transition in={open} timeout={duration}>
      {(state) => (
        <div
          style={{
            visibility: open === null ? "hidden" : "visible",
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {props.children}
        </div>
      )}
    </Transition>
  );
};
const FadeTopDelayClose = (props) => {
  // const [init, setInit] = useState(false);
  const [open, setOpen] = useState(false);
  // useEffect(() => {

  // }, []);
  const duration = 1500;

  const defaultStyleRevert = {
    transition: `opacity ${duration}ms cubic-bezier(0.31, 0.89, 0.76, 1.07),margin-top ${duration}ms cubic-bezier(0.31, 0.89, 0.76, 1.07)`,
    opacity: 1,
    marginTop: -20,
  };

  const transitionStylesRevert = {
    entering: { opacity: 0, marginTop: -40 },
    entered: { opacity: 0, marginTop: -40 },
    exiting: { opacity: 1, marginTop: 0 },
    exited: { opacity: 1, marginTop: 0 },
  };

  useEffect(() => {
    load(); // eslint-disable-next-line
  }, []);
  const load = () => {
    console.log("entro");
    if (props.open === false)
      setTimeout(
        () => {
          setOpen(true);
        },
        props.columna === 4
          ? 0
          : props.columna === 3
          ? 250
          : props.columna === 2
          ? 500
          : 750
      );
  };
  return (
    <Transition in={open} timeout={duration}>
      {(state) => (
        <div
          style={{
            ...defaultStyleRevert,
            ...transitionStylesRevert[state],
          }}
        >
          {props.children}
        </div>
      )}
    </Transition>
  );
};

const MenuIcon = () => {
  return (
    <div className="icMenu">
      <div className="a"></div>
      <div className="b"></div>
      <div className="c"></div>
    </div>
  );
};
const useStylesAddIcon = makeStyles((theme) => ({
  root: {
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // fontSize: "30px !important",
    // display: "inline-block",
    // padding: "0 15px",
    // background:"trasparent",
    // transform:"rotate(45deg)"
  },
  close: {
    width: 50,
    height: 50,
    opacity: 1,
    transition: "all 0.3s ease 0s",
    "&:hover": {
      height: 40,
      width: 40,
    },
    // -moz-transition: all 0.3s ease 0s;
    // -o-transition: all 0.3s ease 0s;
    // -ms-transition: all 0.3s ease 0s;
    // -webkit-transition: all 0.3s ease 0s;
    // transition: "all 0.3s ease 0s"
  },
}));
const AddIcon = () => {
  const classes = useStylesAddIcon();
  return (
    <div className={classes.root}>
      <img
        alt={"close icon"}
        src={require("../assets/menu/close-icon.svg")}
        className={classes.close}
      />
    </div>
  );
};

const useStylesFooterMenu = makeStyles((theme) => ({
  // footer
  divider: {
    width: "100%",
    color: "#a47644",
    backgroundColor: "#a47644",
    margin: "10px auto",
  },
  // primera columna
  icono: {
    color: "#a1a1a1",
    width: 25,
    height: 25,
    margin: "0 10px",
  },
  icono_qr: {
    color: "#a1a1a1",
    width: 21,
    height: 21,
    margin: "0 10px",
  },
  // segunda columna
  footerTexto: {
    padding: "6px 1px",
    // display: "block",
    color: "#bababa",
    fontSize: 11,
    fontFamily: "Roboto-Rel",
    minHeight: 48,

    "&:hover": {
      color: "#ffffff",
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },

  separador: {
    color: "#bababa",
    fontSize: 11,
    padding: "6px 15px 6px 17px",
    fontFamily: "Rel-Bold",
  },

  containerSubmenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  // tercera columana
  containerLanguage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  botonIdioma: {
    color: "#bababa",
    fontSize: 11,
    fontFamily: "Roboto-Rel",
    padding: 0,
    minHeight: 48,
    minWidth: 35,
    "&:hover": {
      color: "#ffffff",
    },
  },
  botonIdiomaSelected: {
    color: "#ffffff",
    fontSize: 11,
    fontFamily: "Roboto-Bold",
    padding: 0,
    minHeight: 48,
    minWidth: 35,
    "&:hover": {
      color: "#ffffff",
    },
  },
  paperQR: {
    backgroundColor: "#f4eee2",
    // marginTop:-50
  },
  iconoQR: {
    width: 300,
    height: 300,
  },
}));

const FooterMenu = ({ open, onClose }) => {
  const classes = useStylesFooterMenu();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleHover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openLocal = Boolean(anchorEl);
  const id = openLocal ? "simple-popover" : undefined;

  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  return (
    <div style={{ maxWidth: 1200, margin: "auto auto 20px", width: "100%" }}>
      <FadeBotton open={open} paddingTop={20}>
        <Grid container>
          <Divider className={classes.divider} />
          <Grid item xs={12} sm={12} md={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Link
                target="_blank"
                rel="noopener"
                href="https://download.sena.cl"
                className={classes.link}
              >
                <Button onClick={onClose} className={classes.footerTexto}>
                  <img
                    alt={"user icon"}
                    src={require("../assets/user-icon.svg")}
                    className={classes.close}
                    style={{ marginRight: "8px", width: 20, height: 20 }}
                  />
                  Login
                </Button>
              </Link>
              <Typography className={classes.separador}>|</Typography>
              <Link
                target="_blank"
                rel="noopener"
                href="https://www.instagram.com/senawinery/"
              >
                <InstagramIcon className={classes.icono} />
              </Link>
              <Link
                target="_blank"
                rel="noopener"
                href="https://weixin.qq.com/r/K0gzK6TE2vmlreBI9x05"
              >
                <img
                  onMouseEnter={handleHover}
                  // onMouseLeave={() => setAnchorEl(null)}
                  alt={"wechat"}
                  src={require("../assets/menu/wechat_icon_transparente.png")}
                  className={classes.icono_qr}
                />
                <Popover
                  id={id}
                  open={openLocal}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  style={{ marginTop: -100 }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                >
                  <Zoom in={true}>
                    <Paper elevation={4} className={classes.paperQR}>
                      <img
                        alt={"wechat"}
                        src={require("../assets/menu/wechat.jpg")}
                        className={classes.iconoQR}
                      />
                    </Paper>
                  </Zoom>
                </Popover>
              </Link>
              <Link
                target="_blank"
                rel="noopener"
                href="https://www.youtube.com/channel/UC1q_v1g53IQA5a9sQmA7N7w/featured"
              >
                <YouTubeIcon className={classes.icono} />
              </Link>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Hidden mdUp>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Link
                  className={classes.link}
                  to={"/Authentify-Your-Bottle"}
                  component={RouterLink}
                >
                  <Button onClick={onClose} className={classes.footerTexto}>
                    {t("header.footer.auth")}
                  </Button>
                </Link>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://download.sena.cl"
                  className={classes.link}
                >
                  <Button onClick={onClose} className={classes.footerTexto}>
                    <img
                      alt={"user icon"}
                      src={require("../assets/user-icon.svg")}
                      className={classes.close}
                      style={{ marginRight: "8px", width: 20, height: 20 }}
                    />
                    Login
                  </Button>
                </Link>
                {/* <Button
                  onClick={() => setOpenModal(true)}
                  className={classes.footerTexto}
                >
                  {t("header.footer.auth")}
                </Button> */}
              </div>
            </Hidden>
            <div className={classes.containerSubmenu}>
              <Hidden smDown>
                <Link
                  className={classes.link}
                  to={"/Authentify-Your-Bottle"}
                  component={RouterLink}
                >
                  <Button onClick={onClose} className={classes.footerTexto}>
                    {t("header.footer.auth")}
                  </Button>
                </Link>
                {/* 
                <Button
                  onClick={() => setOpenModal(true)}
                  className={classes.footerTexto}
                >
                  {t("header.footer.auth")}
                </Button> */}

                <Typography className={classes.separador}>|</Typography>
              </Hidden>
              {/* <Link
                className={classes.link}
                to={"/Find-A-Reseller"}
                component={RouterLink}
              >
                <Button onClick={onClose} className={classes.footerTexto}>
                  {" "}
                  FIND A RESELLER
                </Button>
              </Link> */}
              {/* <Typography className={classes.separador}>|</Typography> */}
              <Link
                className={classes.link}
                to={"/Contact"}
                component={RouterLink}
              >
                <Button onClick={onClose} className={classes.footerTexto}>
                  {t("header.footer.contacto")}
                </Button>
              </Link>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <div className={classes.containerLanguage}>
              <Button
                onClick={() => handleClick("engv10")}
                className={
                  t("idioma") === "ENG"
                    ? classes.botonIdiomaSelected
                    : classes.botonIdioma
                }
              >
                EN
              </Button>
              <Typography className={classes.separador}>|</Typography>
              <Button
                onClick={() => handleClick("chiv10")}
                className={
                  t("idioma") === "CHI"
                    ? classes.botonIdiomaSelected
                    : classes.botonIdioma
                }
              >
                中文
              </Button>
              <Typography className={classes.separador}>|</Typography>
              <Button
                onClick={() => handleClick("espv10")}
                className={
                  t("idioma") === "ESP"
                    ? classes.botonIdiomaSelected
                    : classes.botonIdioma
                }
              >
                ES
              </Button>
              <Typography className={classes.separador}>|</Typography>
              <Button
                onClick={() => handleClick("japv10")}
                className={
                  t("idioma") === "JAP"
                    ? classes.botonIdiomaSelected
                    : classes.botonIdioma
                }
              >
                日本 語
              </Button>
            </div>
          </Grid>
        </Grid>
      </FadeBotton>
      <Dialog
        onClose={() => setOpenModal(false)}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogContent dividers>
          <div className={"close-icon"} onClick={() => setOpenModal(false)}>
            <AddIcon />
          </div>
          <Typography gutterBottom className="text-modal">
            {t("idioma") === "ESP" ? (
              <React.Fragment>
                Nos encontramos haciendo actualizaciones.
                <br /> Para consultas sobre Prooftag por favor
                <br /> enviar email a{" "}
              </React.Fragment>
            ) : (
              <React.Fragment>
                We are currently making some updates.
                <br /> For Prooftag enquiries please
                <br /> contact{" "}
              </React.Fragment>
            )}
            <a href="mailto:constanza.parraguez@vfch.cl">
              constanza.parraguez@vfch.cl
            </a>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};
const useStylesItems = makeStyles((theme) => ({
  textoLogo: {
    fontSize: 13,
    color: "#ffffff",
    fontFamily: "Trajan-Bold",
    textAlign: "center",
    padding: "20px 0",
  },
  titulos: {
    fontSize: 13,
    color: "#a47644",
    fontFamily: "Trajan-Bold",
    textAlign: "center",
    padding: "20px 0",
  },
  itemMenu: {
    fontSize: 13,
    color: "#ffffff",
    fontFamily: "Roboto-Bold",
    textAlign: "center",
    padding: "10px 0",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0,0,0,0.6)",
    },
  },
  itemMenuSub: {
    fontSize: 11,
    color: "#bababa",
    fontFamily: "Roboto-Rel",
    textAlign: "center",
    padding: "15px 0",
    cursor: "pointer",
    letterSpacing: 1.2,
    "&:hover": {
      color: "#ffffff",
    },
  },
  containerMenu: {},
}));

const NavMobile = ({ open, setPosicionPortada, onClose }) => {
  const classes = useStylesItems();
  const history = useHistory();
  const { t } = useTranslation();
  const [position, setPosition] = useState(0);
  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        className={classes.columna}
        onClick={() => setPosicionPortada(1)}
      >
        <FadeBotton open={open} paddingTop={20}>
          <Typography
            className={classes.titulos}
            onClick={
              position === 1 ? () => setPosition(0) : () => setPosition(1)
            }
          >
            {t("header.col1.titulo")}
          </Typography>
        </FadeBotton>

        <Collapse
          in={open === false ? false : position === 1 ? true : false}
          timeout="auto"
          unmountOnExit
        >
          <a href="/Chile-first-icon">
            <Typography onClick={onClose} className={classes.itemMenu}>
              {t("header.col1.chile")}
            </Typography>
          </a>
          <Typography className={classes.itemMenu}>
            {t("header.col1.international-1")}
            <br /> {t("header.col1.international-2")}
          </Typography>

          <Typography
            onClick={() => {
              history.push("/The-Berlin-Tasting");
              onClose();
            }}
            className={classes.itemMenuSub}
          >
            {t("header.col1.berlin")}
          </Typography>

          {t("idioma") === "JAP" ? (
            <Typography
              onClick={() => {
                history.push("/Sena-Vertical-Tasting-Tour");
                onClose();
              }}
              className={classes.itemMenuSub}
            >
              {t("header.col1.vertical")}
              <br />
              {t("header.col1.vertical-2")}
            </Typography>
          ) : (
            <Typography
              onClick={() => {
                history.push("/Sena-Vertical-Tasting-Tour");
                onClose();
              }}
              className={classes.itemMenuSub}
            >
              {t("header.col1.vertical")}
            </Typography>
          )}

          <Typography
            onClick={() => {
              history.push("/Sena-Reaches-Perfection");
              onClose();
            }}
            className={classes.itemMenuSub}
          >
            {t("header.col1.reaches")}
          </Typography>

          <Typography
            onClick={() => {
              history.push("/Decanter-Man-Of-The-Year-2018");
              onClose();
            }}
            className={classes.itemMenuSub}
          >
            {t("header.col1.decanter")}
          </Typography>

          <Typography
            onClick={() => {
              history.push("/The-Legends-Of-Chile-Tour");
              onClose();
            }}
            className={classes.itemMenuSub}
          >
            {t("header.col1.legends")}
          </Typography>

          <Typography
            onClick={() => {
              history.push("/International-Ratings");
              onClose();
            }}
            className={classes.itemMenuSub}
          >
            {" "}
            {t("header.col1.ratings")}
          </Typography>
        </Collapse>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.columna}
        onClick={() => setPosicionPortada(2)}
      >
        <FadeBotton open={open} paddingTop={20}>
          <Typography
            className={classes.titulos}
            onClick={
              position === 2 ? () => setPosition(0) : () => setPosition(2)
            }
          >
            {t("header.col2.titulo")}
          </Typography>
        </FadeBotton>
        <Collapse
          in={open === false ? false : position === 2 ? true : false}
          timeout="auto"
          unmountOnExit
        >
          <Typography
            className={classes.itemMenu}
            onClick={() => {
              history.push("/Chile-a-viticultural-paradise");
              onClose();
            }}
          >
            {t("header.col2.chile")}
          </Typography>
          <Typography
            className={classes.itemMenu}
            onClick={() => {
              history.push("/The-Aconcagua-Valley");
              onClose();
            }}
          >
            {t("header.col2.aconcagua")}
          </Typography>
          <Typography
            onClick={() => {
              history.push("/The-Vineyard");
              onClose();
            }}
            className={classes.itemMenu}
          >
            {t("header.col2.vineyard")}
          </Typography>

          <Typography
            onClick={() => {
              history.push("/On-The-Biodynamic-Path");
              onClose();
            }}
            className={classes.itemMenuSub}
          >
            {t("header.col2.biodynamic")}
          </Typography>

          <Typography
            onClick={() => {
              history.push("/The-Hilltop-Mirador");
              onClose();
            }}
            className={classes.itemMenuSub}
          >
            {t("header.col2.hilltop")}
          </Typography>
        </Collapse>
      </Grid>
      <Grid
        item
        xs={12}
        onClick={() => setPosicionPortada(3)}
        className={classes.columna}
      >
        <FadeBotton open={open} paddingTop={20}>
          <Typography
            className={classes.titulos}
            onClick={
              position === 3 ? () => setPosition(0) : () => setPosition(3)
            }
          >
            {t("header.col3.titulo")}
          </Typography>
        </FadeBotton>
        <Collapse
          in={open === false ? false : position === 3 ? true : false}
          timeout="auto"
          unmountOnExit
        >
          <Typography
            className={classes.itemMenu}
            onClick={() => {
              history.push("/Wines/sena/2022");
              onClose();
            }}
          >
            {t("header.col3.sena")}
          </Typography>
          <Typography
            className={classes.itemMenu}
            onClick={() => {
              history.push("/Wines/rocas_de_sena/2022");
              onClose();
            }}
          >
            {t("rocas_de_sena_2020.title")}
          </Typography>
          <Typography
            className={classes.itemMenu}
            onClick={() => {
              history.push("/Find-A-Reseller");
              onClose();
            }}
          >
            {t("header.col3.reseller")}
          </Typography>
          {/* <Typography className={classes.itemMenu}>ROCAS DE SEÑA</Typography> */}
        </Collapse>
      </Grid>
      <Grid
        item
        xs={12}
        onClick={() => setPosicionPortada(4)}
        style={{ padding: "0 15px" }}
      >
        <FadeBotton open={open} paddingTop={20}>
          <Typography
            className={classes.titulos}
            onClick={
              position === 4 ? () => setPosition(0) : () => setPosition(4)
            }
          >
            {t("header.col4.titulo")}
          </Typography>
        </FadeBotton>
        <Collapse
          in={open === false ? false : position === 4 ? true : false}
          timeout="auto"
          unmountOnExit
        >
          <Typography
            className={classes.itemMenu}
            onClick={() => {
              history.push("/News");
              onClose();
            }}
          >
            {t("header.col4.news")}
          </Typography>
          <Books
            className={classes.itemMenu}
            titulo={
              t("idioma") === "CHI"
                ? ["《赛妮娅：", "一段激动人心的旅程》电子书"]
                : t("idioma") === "JAP"
                ? ["シグナル E-ブック"]
                : ["SEÑA E-BOOK"]
            }
            link={
              t("idioma") === "CHI"
                ? "https://online.flippingbook.com/view/838774248/"
                : t("idioma") === "CHI"
                ? "https://online.flippingbook.com/view/17449948/"
                : "https://online.flippingbook.com/view/131190098/"
            }
          />
          <Books
            className={classes.itemMenu}
            titulo={
              t("idioma") === "CHI"
                ? ["《柏林盲品会》电子书"]
                : t("idioma") === "JAP"
                ? ["ベルリン", "テイスティング Eブック"]
                : ["THE BERLIN TASTING", "E-BOOK"]
            }
            link={
              t("idioma") === "CHI"
                ? "https://online.flippingbook.com/view/834216966/"
                : "https://online.flippingbook.com/view/834580963/"
            }
            buttonlink={"https://online.flippingbook.com/view/681966240/"}
            buttontext={"한국어 버전."}
          />
        </Collapse>
      </Grid>
    </React.Fragment>
  );
};
const portadas = [
  require("../assets/menu/initial.jpg"),
  require("../assets/menu/story.jpg"),
  require("../assets/menu/terroir.jpg"),
  require("../assets/menu/wine.jpg"),
  require("../assets/menu/news.jpg"),
];

const CustomDrawer = withStyles({
  root: {
    "& div.MuiDrawer-paperAnchorTop": {
      top: 0,
      // padding: 15,
      // minHeight: 900,
      // maxHeight:"100vh",
      height: "100vh",
      overflowY: "hidden",
      overflowX: "hidden",
    },
    "& div.MuiPaper-root": {
      background:
        "transparent linear-gradient(90deg, #090C1F 0%, #202747 50%, #0F132B 100%) 0% 0% no-repeat padding-box",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "100vh",
      // transition: `cubic-bezier(0, 0.35, 0.82, 0.58) !important`,
      transitionDuration: ".5s !important",
    },
  },
})(Drawer);
const Header = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [posicionPortada, setPosicionPortada] = useState(0);
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);

  const useStyles = makeStyles((theme) => ({
    appBar: {
      boxShadow: "none",
      width: "100%",
      maxWidth: 1200,
      margin: "auto",
      left: 0,
      right: 0,
      zIndex: 1301,
      background: "transparent",
    },
    button: {
      height: 50,
      width: 110,
      fontSize: 14,
      fontFamily: "Roboto-Light",
      textTransform: "none",
      color: "#ffffff !important",
      background: "#000000d1",
      borderRadius: 0,
      padding: "0 10px",
      textAlign: "initial",
      // transition: "all 0.3s ease 0s",
      "&:hover": {
        color: "#ffffff !important",
        background: "#000000d1",
      },
    },
    iconOpen: {
      height: 30,
      width: 30,
      color: "#AA6F3D !important",
    },
    buttonClose: {
      height: 50,
      width: 50,
      minWidth: 50,
      padding: 0,
      fontSize: 14,
      fontFamily: "Roboto-Light",
      textTransform: "none",
      color: "#ffffff !important",
      background: "#000000d1",
      borderRadius: 0,
      textAlign: "initial",
      // transition: "all 0.3s ease 0s",
      "&:hover": {
        color: "#ffffff !important",
        background: "#000000d1",
      },
    },
    iconClose: {
      height: 45,
      width: 45,
      color: "white !important",
      transform: "rotate(45deg)",
      transition: "all 0.3s ease 0s",
      "&:hover": {
        height: 40,
        width: 40,
      },
    },
    logoNav: {
      height: 50,
      // width:95,
    },
    containerLogo: {
      width: "100%",
      height: 130,
      [theme.breakpoints.up("md")]: {
        height: 155,
      },
      [theme.breakpoints.up("xl")]: {
        height: 215,
      },
    },
    logo: {
      cursor: "pointer",
      height: 110,
      width: 110,
      margin: "auto",
      marginTop: 70,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      background: `url(${require("../assets/logo_sena_color.png")})`,
      transition: "all 0.2s",
      "&:hover": {
        // marginTop:75,
        height: 110,
        width: 110,
      },
      [theme.breakpoints.up("md")]: {
        height: 120,
        width: 120,
        marginTop: "auto",
        marginBottom: 60,
        "&:hover": {
          // paddingTop: 5,
          height: 123,
          width: 123,
        },
      },
    },
    logoDorado: {
      height: 33,
      width: 76,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      background: `url(${require("../assets/logo_sena_oro.png")})`,
    },
    textoLogo: {
      fontSize: 13,
      color: "#ffffff",
      fontFamily: "Trajan-Bold",
      textAlign: "center",
      padding: "20px 0",
    },
    titulos: {
      fontSize: 13,
      color: "#a47644",
      fontFamily: "Trajan-Bold",
      textAlign: "center",
      padding: "25px 0",
      letterSpacing: 1.2,
    },
    itemMenu: {
      fontSize: 11,
      color: "#bababa",
      fontFamily: "Roboto-Bold",
      textAlign: "center",
      padding: "10px 0",
      cursor: "pointer",
      letterSpacing: 1.2,
      "&:hover": {
        color: "#ffffff",
      },
    },
    itemMenuSub: {
      fontSize: 11,
      color: "#bababa",
      fontFamily: "Roboto-Rel",
      textAlign: "center",
      padding: "15px 0",
      cursor: "pointer",
      letterSpacing: 1.2,
      "&:hover": {
        color: "#ffffff",
      },
      [theme.breakpoints.up("md")]: {
        padding: "10px 0",
      },
    },
    containerMenuLogo: {
      [theme.breakpoints.up("sm")]: {
        // paddingTop: "12vh",
        display: "flex",
        justifyContent: "center",
        //  alignItems:"center"
        position: "absolute",
        width: "100%",
        top: "24%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      },
      [theme.breakpoints.up("md")]: {
        top: "20%",
      },
      [theme.breakpoints.up("lg")]: {
        top: "40%",
      },
    },
    containerMenu: {
      width: "100%",
      height: "auto",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        maxWidth: 1200,
        margin: "auto",
        marginTop: 30,
        top: "55%",
        left: "50%",
      },
      [theme.breakpoints.up("md")]: {
        // marginTop: "15vh",
        top: "35%",
        minHeight: 550,
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "10vh",
        top: "50%",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "20vh",
        height: 610,
        minHeight: 610,
      },
    },
    columna: {
      padding: "0 20px",
    },
    //portadas
    containerPortada: {
      zIndex: -1,
      position: "absolute",
      height: "110vh",
      minHeight: 800,
      width: "100%",
      // overflowY: "scroll",
      // transition: "all 16s",
    },
    portadaInit: {
      position: "absolute",
      zIndex: -2,
      top: "50%",
      left: "50%",
      height: "100%",
      width: "auto",
      minWidth: "100%",
      minHeight: "100%",
      transform: "translate(-50%,-50%)",
      objectFit: "cover",
      transition: "all 1s",
    },
    portada: {
      position: "absolute",
      zIndex: -1,
      top: "50%",
      left: "50%",
      height: "100%",
      width: "auto",
      minWidth: "100%",
      minHeight: "100%",
      transform: "translate(-50%,-50%)",
      objectFit: "cover",
      transition: "all 1s",
      opacity: 0,
    },
    portadaActive: {
      position: "absolute",
      zIndex: -1,
      top: "50%",
      left: "50%",
      height: "100%",
      width: "auto",
      minWidth: "100%",
      minHeight: "100%",
      transform: "translate(-50%,-50%)",
      objectFit: "cover",
      transition: "all 1s",
      opacity: 1,
    },
    toolbar: {
      padding: 0,
      minHeight: "auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        padding: "0 16px",
      },
    },
  }));
  const classes = useStyles();

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(!openDrawer);
    setTimeout(function () {
      setOpen(!open);
    }, 1000);
  };
  const handleClose = () => {
    setOpen(!open);

    setTimeout(function () {
      setOpenDrawer(!openDrawer);
      setOpen(null);
    }, 2000);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div></div>
          {openDrawer === false ? (
            <Button
              title="menu"
              disabled={
                open === null ? false : open !== openDrawer ? true : false
              }
              className={classes.buttonClose}
              id={"boton-menu-open"}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </Button>
          ) : (
            <Button
              disabled={open !== openDrawer ? true : false}
              className={classes.buttonClose}
              onClick={handleClose}
            >
              <AddIcon className={classes.iconClose} />
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <CustomDrawer anchor={"top"} open={openDrawer} onClose={handleClose}>
        <div className={classes.containerPortada}>
          {portadas.map((portada, index) => (
            <img
              key={index}
              alt={`portada-${index}`}
              className={
                posicionPortada === index
                  ? classes.portadaActive
                  : classes.portada
              }
              src={portada}
            />
          ))}
        </div>

        <div className={classes.containerMenu}>
          <div className={classes.containerLogo}>
            <div
              className={classes.logo}
              onClick={() => {
                history.push("/");
                handleClose();
              }}
            ></div>
          </div>
          <Grid container alignItems="flex-start" justify={"space-around"}>
            <Hidden mdUp>
              <NavMobile
                open={open}
                onClose={handleClose}
                setPosicionPortada={setPosicionPortada}
              />
            </Hidden>
            <Hidden smDown>
              <Grid
                item
                md={3}
                className={classes.columna}
                onMouseEnter={() => setPosicionPortada(1)}
                onMouseLeave={() => setPosicionPortada(0)}
              >
                <FadeBotton open={open} paddingTop={20}>
                  <Typography className={classes.titulos}>
                    {t("header.col1.titulo")}
                  </Typography>
                </FadeBotton>
                {open === null ? null : open === true ? (
                  <FadeTopDelay open={open} columna={1}>
                    <div style={{ position: "relative" }}>
                      <a href="/Chile-first-icon">
                        <Typography
                          onClick={handleClose}
                          className={classes.itemMenu}
                        >
                          {t("header.col1.chile")}
                        </Typography>
                      </a>
                      <Typography
                        className={classes.itemMenu}
                        style={{ position: "relative" }}
                        onMouseEnter={() => setOpen1(true)}
                        onMouseLeave={() => setOpen1(false)}
                      >
                        {t("header.col1.international-1")}
                        <br /> {t("header.col1.international-2")}
                      </Typography>
                      <Collapse
                        in={open1}
                        timeout="auto"
                        unmountOnExit
                        onMouseEnter={() => setOpen1(true)}
                        onMouseLeave={() => setOpen1(false)}
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          className={classes.itemMenuSub}
                          onClick={() => {
                            history.push("/The-Berlin-Tasting");
                            handleClose();
                          }}
                        >
                          {t("header.col1.berlin")}
                        </Typography>
                        {t("idioma") === "JAP" ? (
                          <Typography
                            onClick={() => {
                              history.push("/Sena-Vertical-Tasting-Tour");
                              handleClose();
                            }}
                            className={classes.itemMenuSub}
                          >
                            {t("header.col1.vertical")}
                            <br />
                            {t("header.col1.vertical-2")}
                          </Typography>
                        ) : (
                          <Typography
                            onClick={() => {
                              history.push("/Sena-Vertical-Tasting-Tour");
                              handleClose();
                            }}
                            className={classes.itemMenuSub}
                          >
                            {t("header.col1.vertical")}
                          </Typography>
                        )}

                        <Typography
                          onClick={() => {
                            history.push("/Sena-Reaches-Perfection");
                            handleClose();
                          }}
                          className={classes.itemMenuSub}
                        >
                          {t("header.col1.reaches")}
                        </Typography>

                        <Typography
                          onClick={() => {
                            history.push("/Decanter-Man-Of-The-Year-2018");
                            handleClose();
                          }}
                          className={classes.itemMenuSub}
                        >
                          {t("header.col1.decanter")}
                        </Typography>

                        <Typography
                          onClick={() => {
                            history.push("/The-Legends-Of-Chile-Tour");
                            handleClose();
                          }}
                          className={classes.itemMenuSub}
                        >
                          {t("header.col1.legends")}
                        </Typography>

                        <Typography
                          onClick={() => {
                            history.push("/International-Ratings");
                            handleClose();
                          }}
                          className={classes.itemMenuSub}
                        >
                          {t("header.col1.ratings")}
                        </Typography>
                      </Collapse>
                    </div>
                  </FadeTopDelay>
                ) : (
                  <FadeTopDelayClose open={open} columna={1}>
                    <div style={{ position: "relative" }}>
                      <Typography className={classes.itemMenu}>
                        {t("header.col1.chile")}
                      </Typography>
                      <Typography
                        className={classes.itemMenu}
                        style={{ position: "relative" }}
                        onMouseEnter={() => setOpen1(true)}
                        onMouseLeave={() => setOpen1(false)}
                      >
                        {t("header.col1.international-1")}
                        <br /> {t("header.col1.international-2")}
                      </Typography>
                      <Collapse
                        in={open1}
                        timeout="auto"
                        unmountOnExit
                        onMouseEnter={() => setOpen1(true)}
                        onMouseLeave={() => setOpen1(false)}
                        style={{ position: "absolute", width: "100%" }}
                      >
                        <Typography className={classes.itemMenuSub}>
                          {t("header.col1.berlin")}
                        </Typography>
                        {t("idioma") === "JAP" ? (
                          <Typography className={classes.itemMenuSub}>
                            {t("header.col1.vertical")}
                            <br />
                            {t("header.col1.vertical-2")}
                          </Typography>
                        ) : (
                          <Typography className={classes.itemMenuSub}>
                            {t("header.col1.vertical")}
                          </Typography>
                        )}
                        <Typography className={classes.itemMenuSub}>
                          {t("header.col1.reaches")}
                        </Typography>
                        <Typography className={classes.itemMenuSub}>
                          {t("header.col1.decanter")}
                        </Typography>
                        <Typography className={classes.itemMenuSub}>
                          {t("header.col1.legends")}
                        </Typography>
                        <Typography className={classes.itemMenuSub}>
                          {t("header.col1.ratings")}
                        </Typography>
                      </Collapse>
                    </div>
                  </FadeTopDelayClose>
                )}
              </Grid>
              <Grid
                item
                // sm={3}
                className={classes.columna}
                onMouseEnter={() => setPosicionPortada(2)}
                onMouseLeave={() => setPosicionPortada(0)}
              >
                <FadeBotton open={open} paddingTop={20}>
                  <Typography className={classes.titulos}>
                    {t("header.col2.titulo")}
                  </Typography>
                </FadeBotton>
                {open === null ? null : open === true ? (
                  <FadeTopDelay
                    delay={2000}
                    open={open}
                    marginTop={-40}
                    columna={2}
                  >
                    <div style={{ position: "relative" }}>
                      <Typography
                        className={classes.itemMenu}
                        onClick={() => {
                          history.push("/Chile-a-viticultural-paradise");
                          handleClose();
                        }}
                      >
                        {t("header.col2.chile")}
                      </Typography>
                      <Typography
                        className={classes.itemMenu}
                        onClick={() => {
                          history.push("/The-Aconcagua-Valley");
                          handleClose();
                        }}
                      >
                        {t("header.col2.aconcagua")}
                      </Typography>
                      <Typography
                        className={classes.itemMenu}
                        style={{ position: "relative" }}
                        onMouseEnter={() => setOpen3(true)}
                        onMouseLeave={() => setOpen3(false)}
                        onClick={() => {
                          history.push("/The-Vineyard");
                          handleClose();
                        }}
                      >
                        {t("header.col2.vineyard")}
                      </Typography>
                      <Collapse
                        in={open3}
                        timeout="auto"
                        unmountOnExit
                        onMouseEnter={() => setOpen3(true)}
                        onMouseLeave={() => setOpen3(false)}
                        style={{ position: "absolute", width: "100%" }}
                      >
                        <Typography
                          onClick={() => {
                            history.push("/On-The-Biodynamic-Path");
                            handleClose();
                          }}
                          className={classes.itemMenuSub}
                        >
                          {t("header.col2.biodynamic")}
                        </Typography>

                        <Typography
                          onClick={() => {
                            history.push("/The-Hilltop-Mirador");
                            handleClose();
                          }}
                          className={classes.itemMenuSub}
                        >
                          {t("header.col2.hilltop")}
                        </Typography>
                      </Collapse>
                    </div>
                  </FadeTopDelay>
                ) : (
                  <FadeTopDelayClose open={open} columna={2}>
                    <div style={{ position: "relative" }}>
                      <Typography className={classes.itemMenu}>
                        {t("header.col2.chile")}
                      </Typography>
                      <Typography className={classes.itemMenu}>
                        {t("header.col2.aconcagua")}
                      </Typography>
                      <Typography
                        className={classes.itemMenu}
                        style={{ position: "relative" }}
                        onMouseEnter={() => setOpen3(true)}
                        onMouseLeave={() => setOpen3(false)}
                      >
                        {t("header.col2.vineyard")}
                      </Typography>
                      <Collapse
                        in={open3}
                        timeout="auto"
                        unmountOnExit
                        onMouseEnter={() => setOpen3(true)}
                        onMouseLeave={() => setOpen3(false)}
                        style={{ position: "absolute", width: "100%" }}
                      >
                        <Typography className={classes.itemMenuSub}>
                          {t("header.col2.biodynamic")}
                        </Typography>
                        <Typography className={classes.itemMenuSub}>
                          {t("header.col2.hilltop")}
                        </Typography>
                      </Collapse>
                    </div>
                  </FadeTopDelayClose>
                )}
              </Grid>
              <Grid
                item
                // sm={3}
                onMouseEnter={() => setPosicionPortada(3)}
                onMouseLeave={() => setPosicionPortada(0)}
                className={classes.columna}
              >
                <FadeBotton open={open} paddingTop={20}>
                  <Typography className={classes.titulos}>
                    {t("header.col3.titulo")}
                  </Typography>
                </FadeBotton>
                {open === null ? null : open === true ? (
                  <FadeTopDelay open={open} columna={3}>
                    <div>
                      <Typography
                        className={classes.itemMenu}
                        onClick={() => {
                          history.push("/Wines/sena/2022");
                          handleClose();
                        }}
                      >
                        {t("header.col3.sena")}
                      </Typography>
                      <Typography
                        className={classes.itemMenu}
                        onClick={() => {
                          history.push("/Wines/rocas_de_sena/2022");
                          handleClose();
                        }}
                      >
                        {t("rocas_de_sena_2020.title")}
                      </Typography>

                      <Typography
                        className={classes.itemMenu}
                        onClick={() => {
                          history.push("/Find-A-Reseller");
                          handleClose();
                        }}
                      >
                        {t("header.col3.reseller")}
                      </Typography>
                      {/* <Typography className={classes.itemMenu}>
                        ROCAS DE SEÑA
                      </Typography> */}
                    </div>
                  </FadeTopDelay>
                ) : (
                  <FadeTopDelayClose open={open} columna={3}>
                    <div>
                      <Typography className={classes.itemMenu}>
                        {t("header.col3.sena")}
                      </Typography>
                      <Typography className={classes.itemMenu}>
                        {t("rocas_de_sena_2020.title")}
                      </Typography>
                      <Typography className={classes.itemMenu}>
                        {t("header.col3.reseller")}
                      </Typography>
                      {/* <Typography className={classes.itemMenu}>
                        ROCAS DE SEÑA
                      </Typography> */}
                    </div>
                  </FadeTopDelayClose>
                )}
              </Grid>
              <Grid
                item
                onMouseEnter={() => setPosicionPortada(4)}
                onMouseLeave={() => setPosicionPortada(0)}
                style={{ padding: "0 15px" }}
              >
                <FadeBotton open={open} paddingTop={20}>
                  <Typography className={classes.titulos}>
                    {t("header.col4.titulo")}
                  </Typography>
                </FadeBotton>
                {open === null ? null : open === true ? (
                  <FadeTopDelay open={open} columna={4}>
                    <div>
                      <Typography
                        className={classes.itemMenu}
                        onClick={() => {
                          history.push("/News");
                          handleClose();
                        }}
                      >
                        {t("header.col4.news")}
                      </Typography>
                      <Books
                        className={classes.itemMenu}
                        titulo={
                          t("idioma") === "CHI"
                            ? ["《赛妮娅：", "一段激动人心的旅程》电子书"]
                            : t("idioma") === "JAP"
                            ? ["シグナル E-ブック"]
                            : ["SEÑA E-BOOK"]
                        }
                        link={
                          t("idioma") === "CHI"
                            ? "https://online.flippingbook.com/view/838774248/"
                            : t("idioma") === "JAP"
                            ? "https://online.flippingbook.com/view/17449948/"
                            : "https://online.flippingbook.com/view/131190098/"
                        }
                      />
                      <Books
                        className={classes.itemMenu}
                        titulo={
                          t("idioma") === "CHI"
                            ? ["《柏林盲品会》电子书"]
                            : t("idioma") === "JAP"
                            ? ["ベルリン", "テイスティング Eブック"]
                            : ["THE BERLIN TASTING", "E-BOOK"]
                        }
                        link={
                          t("idioma") === "CHI"
                            ? "https://online.flippingbook.com/view/834216966/"
                            : "https://online.flippingbook.com/view/834580963/"
                        }
                        buttonlink={[
                          "https://online.flippingbook.com/view/681966240/",
                          "https://online.flippingbook.com/view/493910173/",
                        ]}
                        buttontext={["한국어 버전.", "日本語版"]}
                      />
                    </div>
                  </FadeTopDelay>
                ) : (
                  <FadeTopDelayClose open={open} columna={4}>
                    <div>
                      <Typography className={classes.itemMenu}>
                        {t("header.col4.news")}
                      </Typography>
                      <Typography className={classes.itemMenu}>
                        {t("idioma") === "CHI" ? (
                          <React.Fragment>
                            《赛妮娅： <br />
                            一段激动人心的旅程》电子书
                          </React.Fragment>
                        ) : t("idioma") === "JAP" ? (
                          ["シグナル E-ブック"]
                        ) : (
                          ["SEÑA E-BOOK"]
                        )}
                      </Typography>
                      <Typography className={classes.itemMenu}>
                        {t("idioma") === "CHI" ? (
                          <React.Fragment>《柏林盲品会》电子书</React.Fragment>
                        ) : t("idioma") === "JAP" ? (
                          <React.Fragment>
                            ベルリン
                            <br />
                            テイスティング Eブック
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            THE BERLIN TASTING <br />
                            E-BOOK
                          </React.Fragment>
                        )}
                      </Typography>
                    </div>
                  </FadeTopDelayClose>
                )}
              </Grid>
            </Hidden>
          </Grid>
        </div>
        <FooterMenu open={open} onClose={handleClose} />
      </CustomDrawer>
    </React.Fragment>
  );
};

Header.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};
export default withWidth()(Header);
